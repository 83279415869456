import * as React from "react"

export const defaultState = {
    search: '', 
    isLoading: true,
    setIsLoding: ()=>{
      defaultState.isLoading = false  
    },
}
export const GlobalContext = React.createContext(defaultState)

export const GlobalProvider = ({ children }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [search, setSearch] = React.useState('');
    const changeLoading = () => {
      setIsLoading(false);
  };

    const changeSearch = (el) => {
      setSearch(el);
  };
  
    return (
      <GlobalContext.Provider
        value={{
            isLoading,
            changeLoading,
            search,
            setSearch
        }}
      >
        {children}
      </GlobalContext.Provider>
    );
  };


export const GlobalContextProvider = GlobalContext.Provider;

export const useGlobalContext = () => React.useContext(GlobalContext);